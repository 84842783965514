<!-- <template>
<div :class="'pray dirr ' + $store.state.direction">
    <div class="prayer_timesCON">
        <div class="s_wrap them_overlay">
            <div class="container">
                <div class="s_row">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <ul class=" nav-tabs iitem">
                                <li>
                                    <a class="left_bottom hvr-ripple-out">
                                        <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/fajer.svg#fajer"></use></svg>
                                        </div>
                                        <h6 class="title- tl">{{ $t("index_fajir") }}</h6>
                                        <h6 class="title- tl">{{prayer.fajer}}</h6>
                                    </a></li>
                                <li>
                                    <a class="left_center hvr-ripple-out">
                                       <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/shoroq.svg#shoroq"></use></svg>
                                        </div>

                                        <h6 class="title- tl">{{ $t("index_sunrise") }}</h6>
                                        <h6 class="title- tl">{{prayer.rise}}</h6>
                                    </a>
                                </li>
                                <li><a class="center hvr-ripple-out">
                                   <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/sun.svg#sun"></use></svg>
                                        </div>
                                        <h6 class="title- tl">{{ $t("index_doher") }}</h6>
                                        <h6 class="title- tl">{{prayer.noon}}</h6>
                                    </a></li>
                                <li><a class="right_center hvr-ripple-out">
                                    <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/ghorp.svg#ghorp"></use></svg>
                                        </div>
                                        <h6 class="title- tl">{{ $t("index_maghrib") }}</h6>
                                        <h6 class="title- tl">{{prayer.ghrob}}</h6>
                                    </a></li>
                                <li><a class="right_bottom hvr-ripple-out">
                                    <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/mid-n.svg#mid-n"></use></svg>
                                        </div>
                                        <h6 class="title- tl">{{ $t("midnight") }}</h6>
                                        <h6 class="title- tl">{{prayer.mid}}</h6>
                                    </a></li>
                            </ul>
                        </div>
                        <div class="panel-body">
                            <div class="tab-content">

                                <div class="tab-pane">
                                    <div class="iitem-text">
                                        <div class="hands">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/hands-praying.svg#hands-praying"></use></svg>
                                        </div>
                                        <h4 class="titl-2 tl">{{ $t("prayer_times") }}</h4>
                                        <p>{{ $t("prayers_in_karbala") }}</p>
                                        <p class="messiri_fonted prayer_date" >
                                            <span class="hidden-sm-and-down" v-text="dateD+' - '"></span>
                                            <span class="hidden-sm-and-down" v-html="dateH"></span> 
                                            <span class="hidden-md-and-up" v-text="dateH_ico"></span>
                                            <span class="hidden-sm-and-down" style="padding-right:5px" v-text="dateM"></span>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="allcontent">
                    <div class=" heade">
                        <h4 class="titl-2 mb-2 tl">{{ $t("prayer_times") }}</h4>
                        <p class="text-dark">{{ $t("prayers_in_karbala") }}</p>
                        <p class="messiri_fonted text-dark" >
                            <span class="hidden-sm-and-down" v-text="dateD+' -'"></span>
                            <span class="hidden-sm-and-down" v-html="dateH"></span> 
                            <span class="hidden-md-and-up" v-text="dateH_ico"></span>
                            <span class="hidden-sm-and-down" v-text="dateM"> </span>
                        </p>
                    </div>

                    <div>
                        <ul class="ul-item">
                            <li>
                                <div class="block2 cui">
                                    <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/fajer.svg#fajer"></use></svg>
                                        </div>
                                        <h6 class="title- tl">{{ $t("index_fajir") }}</h6>
                                        <h6 class="title- tl">{{prayer.fajer}}</h6>
                                </div>
                            </li>
                            <li>
                                <div class="block2 cui">
                                    <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/shoroq.svg#shoroq"></use></svg>
                                        </div>

                                    <h6 class="title- tl">{{ $t("index_sunrise") }}</h6>
                                    <h6 class="title- tl">{{prayer.rise}}</h6>
                                </div>
                            </li>
                            <li>
                                <div class="block2 cui">
                                    <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/sun.svg#sun"></use></svg>
                                        </div>

                                    <h6 class="title- tl">{{ $t("index_doher") }}</h6>
                                    <h6 class="title- tl">{{prayer.noon}}</h6>
                                </div>
                            </li>
                            <li>
                                <div class="block2 cui">
                                    <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/ghorp.svg#ghorp"></use></svg>
                                        </div>

                                    <h6 class="title- tl">{{ $t("index_maghrib") }}</h6>
                                    <h6 class="title- tl">{{prayer.ghrob}}</h6>
                                </div>
                            </li>
                            <li>
                                <div class="block2 cui block3 mb-3">
                                   <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/mid-n.svg#mid-n"></use></svg>
                                        </div>

                                    <h6 class="title- tl">{{ $t("midnight") }}</h6>
                                    <h6 class="title- tl">{{prayer.mid}}</h6>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</template> -->

<template>
    <div class="container pray-m my-10">
        <div class="panl-title content-center">
                <img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" />
                <router-link :to="'/media?lang='+$i18n.locale"
                    style="text-decoration: none !important;">
                    <div class="tl panl-title-text d-inline">{{ $t("prayer_times") }}
                    </div>
                </router-link>
            </div>
        <div class="discrption-m tl mb-3 mt-0">
            {{ $t("prayers_in_karbala") }}
        </div>
        <div class="row">
            <!-- <div class="col-md-1"></div> -->
            <!-- <div class="col-md-5">
                <div class="details">
                    <div>
                        <h2 class="my-5 mx-3">حان الان موعد صلاة  العشائين <br> في كربلاء المقدسة</h2>
                    </div>
                    <div class="note my-5">
                        <span class="small">ملاحظة ..</span>
                    </div>
                    <div class="d-flex">
                        <img src='/assets/img/note.png' alt="" class="mx-5 h-50">
                        <span class="gray">{{ $t("prayers_in_karbala") }}
                        </span>
                    </div>
                </div>
            </div> -->
            <div class="col-md-12">
                <div class="main-button mt-4">
                    
                    <div class="d-flex mobile-column">
                        
                        <div class="m-box text-center col-sm-5 col-md-3 col-lg-2 mr-lg-12" :class="{'active-box': nextPrayer.prayer	 == 'fajer'}">
                            <img :src="nextPrayer.prayer == 'fajer' ? 'assets/img/pray_icons/Shubuh_white.svg': 'assets/img/pray_icons/Shubuh.svg'" alt="" class="border-m">
                                <!-- <svg viewBox="-48 5 140 33"><use xlink:href="/assets/img/pray_icons/Shubuh.svg#view"></use></svg> -->
                            <div class="date-m px-4">
                                <div class="m-name tl">
                                    {{ $t("index_fajir") }}
                                </div>
                                <div class="m-time tl">
                                    {{prayer.fajer}}
                                </div>
                            </div>
                        </div>
                        <div class="m-box text-center col-sm-5 col-md-3 col-lg-2">
                            <img src="/assets/img/pray_icons/shoroq-1.svg" alt="" class="border-m">
                            <div class="date-m px-4">
                                <div class="m-name tl">
                                    {{ $t("index_sunrise") }}

                                </div>
                                <div class="m-time tl">
                                    {{prayer.rise}}
                                </div>
                            </div>
                        </div>
                    <!-- </div>
                    <div class="d-flex mobile-column"> -->
                        <div class="m-box text-center col-sm-5 col-md-3 col-lg-2" :class="{'active-box': nextPrayer.prayer	 == 'noon'}">
                            <img :src="nextPrayer.prayer	 == 'noon' ? '/assets/img/pray_icons/noon-white.svg' : '/assets/img/pray_icons/noon.svg'" alt="" class="border-m">
                            <div class="date-m px-4">
                                <div class="m-name tl">
                                    {{ $t("index_doher") }}

                                </div>
                                <div class="m-time tl">
                                    {{prayer.noon}}
                                </div>
                            </div>
                        </div>
                        <div class="m-box text-center col-sm-5 col-md-3 col-lg-2" :class="{'active-box': nextPrayer.prayer	 == 'ghrob'}">
                            <img :src="nextPrayer.prayer == 'ghrob' ? '/assets/img/pray_icons/ghrop-white.svg' : '/assets/img/pray_icons/ghrop-1.svg'" alt="" class="border-m">
                            <div class="date-m px-4">
                                <div class="m-name tl">
                                    {{ $t("index_maghrib") }}

                                </div>
                                <div class="m-time tl">
                                    {{prayer.ghrob}}
                                </div>
                            </div>
                        </div>
                        <div class="m-box text-center d-sm-block d-none col-sm-5 col-md-3 col-lg-2">
                            <img src="/assets/img/pray_icons/midnight.svg" alt="" class="border-m">
                            <div class="date-m px-4">
                                <div class="m-name tl"> {{ $t("midnight") }} </div>
                                <div class="m-time tl"> {{prayer.mid}} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

let endpoints = [
    // 'https://alkafeel.net/main_bg/data/date_main.json?t='+ Date.now(),
    'salaDate',
    'salaDate/nextPrayer',
]
// import Axios from "axios";
export default {
    name: "prayerTimes",
    data() {
        return {
            dateD: '',
            dateH: '',
            dateM: '',
            dateH_ico: '',
            prayer: {"fajer": "00:00","rise": "00:00","noon": "00:00","ghrob": "00:00","mid": "00:00"},
            date: [],
            nextPrayer: {}
        }
    },
    watch: {
    },
    components: {
    },
    directives: {
    },
    computed: {
    },
    mounted() {
    },
    created () {
        this.ini ();
        // this.loadDate ();
        // this.getTimes ();
    },
    methods:{
        ini () {
            this.$axios
                .all(endpoints.map((endpoint) => this.$axios.get(endpoint)))
                .then(
                    this.$axios.spread((...responses) => {
                    
                    const responsePraym = responses[0].data;
                    const nextPrayer  = responses[1].data;

                    if (typeof(responsePraym[0]) !== 'undefined') {this.prayer = responsePraym[0]}
                    // this.date = responseDate.langs;
                    this.nextPrayer = nextPrayer;
                    // try {
                    //     this.dateD = this.date[this.$i18n.locale].D;
                    //     this.dateH = this.date[this.$i18n.locale].H !== '' ? this.date[this.$i18n.locale].H+' <img src="assets/img/z-ali.svg"> ' : '';
                    //     this.dateH_ico = this.date[this.$i18n.locale].H;
                    //     this.dateM = this.date[this.$i18n.locale].M;
                    // } catch (e) {

                    // }
                    
                    })
                    
                )
        },
        
        loadDate () {
            try {
                this.dateD = this.date[this.$i18n.locale].D;
                this.dateH = this.date[this.$i18n.locale].H !== '' ? this.date[this.$i18n.locale].H+' <img src="assets/img/z-ali.svg"> ' : '';
                this.dateH_ico = this.date[this.$i18n.locale].H;
                this.dateM = this.date[this.$i18n.locale].M;
            } catch (e) {

            }
        },
    },
}
</script>

<style scoped>
    /* .active-box {
        background: #8dc28b !important;
    } 
    .theme--dark .active-box {
        background: #033b01 !important;
    }*/
    .active-box {
        background: #8b9651  !important;
    }
    .active-box .m-name, .active-box .m-time {
        color: #fff;
    }
    .prayer_date * {
        font-size: 16px!important;
    }

    .title-m {
        justify-content: center;
    }

    .title-m  span{
        font-size: 24px;
    }

    .title-m  span img{
        height: 16px;
        margin: 0 8px;
    }

    .discrption-m {
        font-size: 18px;
        justify-content: center;
        display: flex;
        margin: 12px 0px;
    }

    .small {
        font-size: 12px;
        color: #858585;
    }

    .pray-m {
        max-width: 1237px;
    }

    .gray {
        font-size: 14px;
        color: #858585;
    }

    .m-box {
        padding-top: 15px;
        /* width: 250px; */
        height: 130px;
        background: #F5F5F5 ;
        align-items: center;
        border-radius: 4px;
        margin:12px;
    }

    .border-m {
       border-bottom: 2px solid #868686;
       height: 44px;
       padding: 0 16px;
    }

    .m-name tl {
        color: #474747;
        font-weight: bold;
        font-size: 14px;
    }

    .m-time tl {
        color: #646464;
        font-weight: bold;
        font-size: 14px;
    }
    .mobile-column {
        flex-wrap: wrap;
    }
    @media (min-width: 320px) and (max-width: 520px) {
  
        /* .mobile-column {
            flex-direction: column;
        } */

        .m-box {
            width: 43%;
        }
        
    }

@media (min-width: 521px) and (max-width: 960px) {
  
  .m-box {
    width: 43%;
  }

  .details {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .h-50 {
    height: 50px;
  }
  
  .note {
    display: none;
  }
}

</style>